
header {
    background-color: rgb(138, 43, 226);
    display: flex;
    align-items: center;
    height: 100px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(141,161,176,.15);
}

#header-logo {
    flex: 0;
    margin-right: 20px;
    max-width: 100px; /* Set a maximum width for the logo */
    max-height: 40px;
}

#header-name {
    flex: 1;
    text-align: left;
    color: white;
    font-weight: bold;
}

.navbar-container {
    background-color: blueviolet;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 20px; /* Adjust padding to your preference */
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(141, 161, 176, 0.15);
}

/* Navbar links styling */
.navbar-links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center; 
  }
  
  .navbar-links ul li {
    margin-right: 20px; /* Adjust margin to your preference */
  }
  
  .navbar-links a {
    text-decoration: none;
    color: white;
    font-weight: bold;
  }

  #logout-button {
    background-color: #a359e8;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  #logout-button:hover {
    background-color: hsl(271, 76%, 33%); /* Change color on hover */
  }
 